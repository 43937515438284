<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Lista de Entradas</h4>
        <el-col :md="15">
          <el-row type="flex" justify="end">
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="medium"
              @click="openAddEntryModal(null)"
            ></el-button>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="Entries"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="provider.name" label="fornecedor">
      </el-table-column>
      <el-table-column prop="product.name" label="produto"> </el-table-column>
      <el-table-column
        prop="bought_at"
        label="adquirido em"
        :formatter="(r) => dateFormatter.format(new Date(r.bought_at))"
      ></el-table-column>
      <el-table-column
        label="peso aferido"
        :formatter="
          (r) => `${numberFormatter.format(Number(r.weight)) || 0} kg.`
        "
      ></el-table-column>
      <el-table-column
        label="preço por kg."
        :formatter="
          (r) => currencyFormatter.format(Number(r.price_per_kg) || 0)
        "
      ></el-table-column>
      <el-table-column
        label="total"
        :formatter="
          (r) =>
            currencyFormatter.format(
              Number(r.price_per_kg) * Number(r.weight) || 0
            )
        "
      ></el-table-column>
      <el-table-column label="ações" :width="130" fixed="right">
        <template #default="cat">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                title="Editar produto"
                type="primary"
                circle
                @click="openAddEntryModal(cat.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteEntry(cat.row)"
                title="
              Remover entrada do produto?
            "
                ><template #reference>
                  <el-button
                    onlyIcon
                    type="danger"
                    title="Remover produto"
                    circle
                  >
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @update:current-page="currentPage = $event"
      :current-page="currentPage"
      background
      layout="prev, pager, next"
      :page-count="entries?.lastPage || 0"
    >
    </el-pagination>
    <product-entry-modal
      :showModal="showProductEntryModal"
      :entry="entry"
      @close-modal="showProductEntryModal = false"
      @should-update="fetchEntries"
    ></product-entry-modal>
  </el-card>
</template>

<script>
import ProductEntryModal from "./modals/ProductEntry.vue";
export default {
  name: "ProductEntry",
  components: { ProductEntryModal },
  data: () => ({
    isLoading: true,
    entries: null,
    entry: null,
    currentPage: 1,
    category: null,
    showProductEntryModal: false,
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "short",
    }),
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    numberFormatter: new Intl.NumberFormat("pt-BR", {
      style: "decimal",
    }),
  }),
  mounted() {
    this.fetchEntries();
  },
  watch: {
    currentPage() {
      this.fetchEntries();
    },
  },
  computed: {
    Entries() {
      return this.entries?.data || [];
    },
  },
  methods: {
    deleteEntry(entry) {
      fetch(`${this.$store.state.apiUrl}entries/${entry.uid}`, {
        credentials: "include",
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.text();
        })
        .then(() => {
          this.fetchEntries();
          this.$notify({
            title: "Entrada de produto removida com sucesso.",
            type: "success",
            position: "bottom-right",
          });
        })
        .catch((e) => {
          this.$notify({
            title: "Não foi possível remover a entrada",
            type: "error",
            message: e.message,
            position: "bottom-right",
          });
        });
    },
    openAddEntryModal(e) {
      (this.entry = e), (this.showProductEntryModal = true);
    },
    fetchEntries() {
      const url = new URL(`${this.$store.state.apiUrl}entries`);
      url.search = new URLSearchParams({
        pagination: this.currentPage,
        status: "not included",
        limit: 20,
      });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.json();
        })
        .then((json) => (this.entries = json))
        .catch(() => {})
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
</style>

<style>
.select-category {
  margin-right: 8px;
  padding: 4px 0px;
}
</style>