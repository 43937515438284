<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow">
    <el-row type="flex" justify="start">
      <h4 class="label">Cadastrado em:</h4>
    </el-row>
    <el-date-picker
      v-model="entry_.created_at"
      type="date"
      disabled
      size="large"
      format="DD/MM/YYYY hh:mm:ss"
    >
    </el-date-picker>
    <el-row type="flex" justify="start">
      <h4 class="label">Vencimento:</h4>
    </el-row>
    <el-date-picker
      v-model="entry_.expires_at"
      type="date"
      size="large"
      format="DD/MM/YYYY hh:mm:ss"
    >
    </el-date-picker>
    <el-row type="flex" justify="start">
      <h4 class="label">Adquirido em:</h4>
    </el-row>
    <el-date-picker
      v-model="entry_.bought_at"
      type="date"
      size="large"
      format="DD/MM/YYYY hh:mm:ss"
    >
    </el-date-picker>
    <el-row type="flex" justify="start">
      <h4 class="label">Empresa:</h4>
    </el-row>
    <el-select
      remote
      filterable
      clearable
      :loading="!firms"
      :remote-method="fetchFirms"
      value-key="uid"
      v-model="entry_.firm_id"
      size="large"
    >
      <el-option
        v-for="item in Firms"
        :key="item.uid"
        :label="item.name"
        :value="item.uid"
      >
      </el-option>
    </el-select>
    <el-row type="flex" justify="start">
      <h4 class="label">Fornecedor:</h4>
    </el-row>
    <el-select
      remote
      filterable
      clearable
      v-model="entry_.provider_id"
      size="large"
      value-key="uid"
      :remote-method="fetchProviders"
      :loading="!providers"
    >
      <el-option
        v-for="item in Providers"
        :key="item.uid"
        :label="item.name"
        :value="item.uid"
      >
      </el-option>
    </el-select>
    <el-row type="flex" justify="start">
      <h4 class="label">Produto:</h4>
    </el-row>
    <el-select
      v-model="selectedProduct"
      value-key="uid"
      filterable
      clearable
      remote
      reserve-keyword
      size="large"
      :remote-method="(searchName) => fetchProducts(searchName)"
    >
      <el-option
        v-for="item in MountedProducts"
        :key="item?.uid"
        :label="item?.product?.name"
        :value="item"
      >
      </el-option>
    </el-select>
    <el-row type="flex" justify="start">
      <h4 class="label">Peso:</h4>
    </el-row>
    <el-row type="flex" :gutter="4" justify="space-between">
      <el-col :md="12" :sm="24">
        <base-input
          label="Peso aferido:"
          type="money"
          hint="em kg."
          v-model="entry_.weight"
        >
        </base-input>
      </el-col>
      <el-col :md="12" :sm="24">
        <base-input
          label="Total de caixas:"
          type="money"
          hint="em kg."
          v-model="entry_.quantity"
        >
        </base-input>
      </el-col>
    </el-row>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="save"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";

export default {
  props: ["entry", "showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      entry_: {},
      standards: null,
      selectedProduct: null,
      firms: null,
      providers: null,
      products: null,
      isLoadingSave: false,
    };
  },
  mounted() {
    setInterval(() => {
      if (this.isNew) this.entry_.created_at = new Date();
    }, 1000);
  },
  watch: {
    entry(v) {
      if (v) {
        this.entry_ = v || {};
        if (v.firm) {
          this.firms = [v.firm];
          this.entry_.firm_id = v.firm.uid;
        }
        if (v.provider) {
          this.providers = [v.provider];
          this.entry_.provider_id = v.provider.uid;
        }
        if (v.product) {
          this.products = [{ ...(v.product || {}), mounted: [v.mounted] }];
          this.selectedProduct = {
            ...(v.product || {}),
            mounted: [v.mounted],
            productId: v?.product?.uid,
            uid: v?.mounted?.id,
          };
        }
      } else this.entry_ = {};
    },
    selectedProduct(product) {
      if (product) {
        this.entry_.product_id = product.productId;
        this.entry_.product_mounted_id = product.uid;
      }
    },
    showModal(show) {
      if (show && this.isNew) {
        this.fetchFirms(true);
        this.fetchStandards();
        this.fetchProviders();
        this.selectedProduct = null;
      }
    },
  },
  computed: {
    Providers() {
      return this.providers || [];
    },
    Firms() {
      return this.firms || [];
    },
    isNew() {
      return !this.entry;
    },
    Products() {
      return this.products || [];
    },
    MountedProducts() {
      return (
        this.Products?.map((p) =>
          p?.mounted
            ?.map((m) => ({
              ...p,
              uid: m.id,
              productId: p.uid,
              product: {
                name: `${p.name} ${m.cutting ?? ""} ${m.composition ?? ""} ${
                  m.storage ?? ""
                }`,
              },
            }))
            ?.flat()
        )?.flat() || []
      );
    },
    CategoryName() {
      return this?.entry_?.category?.name || "";
    },
    Standards() {
      return this.standards?.map((s) => s.value) || [];
    },
    ModalTitle() {
      return "uid" in (this.entry_ || {})
        ? "Editar Entrada"
        : "Cadastrar Entrada";
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    fetchStandards() {
      const url = new URL(`${this.$store.state.apiUrl}system/preferences`);
      url.search = new URLSearchParams({
        key: "defined_standards",
      });
      fetch(url, {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          this.isLoadingSearch = false;
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then((e) => (this.standards = e));
    },
    fetchFirms(v) {
      const url = new URL(`${this.$store.state.apiUrl}firms`);
      url.search = new URLSearchParams({
        name: v === true ? "" : v,
      });
      if (v)
        fetch(url, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => {
            this.firms = json;
          });
    },
    fetchProviders(v) {
      const url = new URL(`${this.$store.state.apiUrl}partners`);
      url.search = new URLSearchParams({
        is_provider: true,
        searchName: v === true ? "" : v,
      });
      if (v)
        fetch(url, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => (this.providers = json));
    },
    save() {
      this.isLoadingSave = true;
      return !this.isNew ? this.updateEntry() : this.createEntry();
    },
    createEntry() {
      fetch(`${this.$store.state.apiUrl}entries`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(this.entry_),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            this.shouldShow = false;
            this.entry_ = {};
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível cadastrar a entrada",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
        });
    },
    fetchProducts(searchName) {
      const url = new URL(`${this.$store.state.apiUrl}products`);
      url.search = new URLSearchParams({ searchName });
      if (searchName)
        fetch(url, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => (this.products = json))
          .catch(() => (this.hasError = true))
          .finally(() => (this.isLoading = false));
    },
    updateEntry() {
      fetch(`${this.$store.state.apiUrl}entries/${this.entry_.uid}`, {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify({ ...this.entry_ }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível alterar a entrada",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
          this.shouldShow = false;
        });
    },
  },
  name: "ProductEntryModal",
};
</script>
<style scoped>
.el-button {
  max-height: 36px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-switch {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.label {
  margin-top: 10px;
  margin-bottom: 2px;
}
</style>